// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-performer-jsx": () => import("./../src/templates/performer.jsx" /* webpackChunkName: "component---src-templates-performer-jsx" */),
  "component---src-templates-wp-page-jsx": () => import("./../src/templates/wp_page.jsx" /* webpackChunkName: "component---src-templates-wp-page-jsx" */),
  "component---src-pages-bandcamp-jsx": () => import("./../src/pages/bandcamp.jsx" /* webpackChunkName: "component---src-pages-bandcamp-jsx" */),
  "component---src-pages-local-eats-jsx": () => import("./../src/pages/local-eats.jsx" /* webpackChunkName: "component---src-pages-local-eats-jsx" */),
  "component---src-templates-news-post-jsx": () => import("./../src/templates/news_post.jsx" /* webpackChunkName: "component---src-templates-news-post-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bands-jsx": () => import("./../src/pages/bands.jsx" /* webpackChunkName: "component---src-pages-bands-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-comedy-jsx": () => import("./../src/pages/comedy.jsx" /* webpackChunkName: "component---src-pages-comedy-jsx" */),
  "component---src-pages-fest-wrestling-jsx": () => import("./../src/pages/fest-wrestling.jsx" /* webpackChunkName: "component---src-pages-fest-wrestling-jsx" */),
  "component---src-pages-fest-22-teaser-jsx": () => import("./../src/pages/fest22-teaser.jsx" /* webpackChunkName: "component---src-pages-fest-22-teaser-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-invalidator-jsx": () => import("./../src/pages/invalidator.jsx" /* webpackChunkName: "component---src-pages-invalidator-jsx" */),
  "component---src-pages-news-jsx": () => import("./../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-podcasts-jsx": () => import("./../src/pages/podcasts.jsx" /* webpackChunkName: "component---src-pages-podcasts-jsx" */),
  "component---src-pages-reg-jsx": () => import("./../src/pages/reg.jsx" /* webpackChunkName: "component---src-pages-reg-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-style-guide-jsx": () => import("./../src/pages/style-guide.jsx" /* webpackChunkName: "component---src-pages-style-guide-jsx" */)
}

