export const noop = "";

export const COLORS = {
  black: { hex: "#141414", rgba: "rgba(20, 20, 20, 1)" },
  white: { hex: "#f4f0dc", rgba: "rgba(244, 240, 220, 1)" },
  lightgrey: { hex: "#fffaf6", rgba: "rgba(255, 250, 246, 1)" },
  darkgrey: { hex: "#666462", rgba: "rgba(102, 100, 98, 1)" },


  /* #FEST22 */
  red: { hex: "#FF5371", rgba: "rgba(255, 83, 113, 1)" },
  blue: { hex: "#28BFFC", rgba: "rgba(40, 191, 252, 1)" },
  yellow: { hex: "#FFE13B", rgba: "rgba(255, 225, 59, 1)" },

  /*
  // #FEST21
  purple: { hex: "#6649ca", rgba: "rgba(102, 73, 202, 1)" },
  blue: { hex: "#29abe2", rgba: "rgba(41, 171, 226, 1)" },
  orange: { hex: "#DC780B", rgba: "rgba(220, 120, 11, 1)" },

  // #FEST20
  //orange: { hex: "#ff7400", rgba: "rgba(255, 116, 0, 1)" },
  burntorange: { hex: "#b14c00", rgba: "rgba(177, 76, 0, 1)" },
  yellow: { hex: "#f0c500", rgba: "rgba(240, 197, 0, 1)" },
  green: { hex: "#00ff49", rgba: "rgba(0, 255, 73, 1)" }
  */
};

export const THEME = {
  black: COLORS.black,
  white: COLORS.white,

  primary: COLORS.blue

}

export const FONT_FAMILIES = {
  condensed: `
    font-family: "Roboto Condensed", "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
    "ubuntu", sans-serif;
    font-weight: 800;
  `,
  copy: `
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
    "ubuntu", sans-serif;
  `,
  headline: `
    text-transform: uppercase;
    font-family: "Rubik", "League Gothic", "Rubik", -apple-system, BlinkMacSystemFont,
    "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, "segoe ui",
    arial, sans-serif;
    font-weight: normal;
    font-weight: 300;
  `
};
