import React from "react";
import MediaQuery from "react-responsive";
import { css } from "styled-components";

export const MEDIA_QUERY_BREAKPOINTS = {
  DESKTOP: 992,
  TABLET: 768
};

// React Components
export const Desktop = props => (
  <MediaQuery {...props} minWidth={MEDIA_QUERY_BREAKPOINTS.DESKTOP} />
);

export const Tablet = props => (
  <MediaQuery
    {...props}
    minWidth={MEDIA_QUERY_BREAKPOINTS.TABLET}
    maxWidth={MEDIA_QUERY_BREAKPOINTS.DESKTOP - 1}
  />
);
export const Phone = props => (
  <MediaQuery {...props} maxWidth={MEDIA_QUERY_BREAKPOINTS.TABLET - 1} />
);
export const NotPhone = props => (
  <MediaQuery {...props} minWidth={MEDIA_QUERY_BREAKPOINTS.TABLET} />
);

/*
 * Styled-Components Media Queries
 *
 *  Example:
 *  const Container = styled.div`
 *    ${StyledComponentsBreakpoints.Phone`
 *      width: 140px;
 *      margin-top: 10px;
 *      margin-left: 24px;
 *    `};
 *  `;
 *
 */
export const StyledComponentsBreakpoints = {
  Desktop: (...args) => css`
    @media (max-width: ${MEDIA_QUERY_BREAKPOINTS.DESKTOP}px) {
      ${css(...args)};
    }
  `,
  Tablet: (...args) => css`
    @media (max-width: ${MEDIA_QUERY_BREAKPOINTS.TABLET}px) {
      ${css(...args)};
    }
  `,
  Phone: (...args) => css`
    @media (max-width: ${MEDIA_QUERY_BREAKPOINTS.TABLET - 1}px) {
      ${css(...args)};
    }
  `,
  NotPhone: (...args) => css`
    @media (min-width: ${MEDIA_QUERY_BREAKPOINTS.TABLET}px) {
      ${css(...args)};
    }
  `
};
