export const noOpString = ``;

export const getPerformerSortName = str => {
  if (str.substr(0, 4).toLowerCase() === "the ") {
    return str.substr(4).toLowerCase();
  }
  if (str.charAt(0) === `&`) {
    return str.substr(7).toLowerCase();
  }
  if (str.charAt(0) === `Ü` || str.charAt(0) === `ü`) {
    return `u${str.substr(1).toLowerCase()}`;
  }
  if (!isNaN(parseInt(str.charAt(0), 10))) {
    return `zzz${str.toLowerCase()}`;
  }

  return str.toLowerCase();
};

export const getStringFromDate = (
  dateString = new Date().getUTCSeconds().toString()
) => {
  const dateObject = new Date(dateString);

  const dateOptions = {
    year: "numeric",
    weekday: "long",
    month: "long",
    day: "numeric"
  };

  return dateObject.toLocaleString([], dateOptions);
};

export const getShortStringFromDate = (
  dateString = new Date().getUTCSeconds().toString()
) => {
  const dateObject = new Date(dateString);

  const dateOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric"
  };

  return dateObject.toLocaleString([], dateOptions);
};

export const forceHttps = (str = "") =>
  str
    .split("http://thefestfl")
    .join("https://thefestfl")
    .split(`href="https://thefestfl.com/`)
    .join(`href="/`);

export const sortTitles = (a, b) => {
  const articles = ["a", "an", "the"];
  const re = new RegExp(`^(?:(${articles.join("|")}) )(.*)$`);
  const replacer = ($0, $1, $2) => `${$2}, ${$1}`;

  const sortableA = a.node.title.rendered.toLowerCase().replace(re, replacer);
  const sortableB = b.node.title.rendered.toLowerCase().replace(re, replacer);

  if (sortableA === sortableB) {
    return 0;
  }
  if (sortableA < sortableB) {
    return -1;
  }

  return 1;
};

export const prettyPrintHtmlEntitiesInString = (text = "") => {
  if (!text || text === null) { text = ''; }

  return text
    .split("&#038;")
    .join("&")
    .split("&#8217;")
    .join("’")
    .split("&#8220;")
    .join("“")
    .split("&#8221;")
    .join("”");
};

export const getDomainFromUrl = (url = "") =>
  url
    .replace("http://", "")
    .replace("https://", "")
    .replace("www.", "")
    .split("/")[0];

export const useStaticDomain = (url = "") =>
  url
    .split("admin.thefestfl.com/fest22/wp-content/uploads/")
    .join("static.thefestfl.com/fest22/")
    .split("admin.thefestfl.com/fest21/wp-content/uploads/")
    .join("static.thefestfl.com/fest21/")
    .split("admin.thefestfl.com/fest20/wp-content/uploads/")
    .join("static.thefestfl.com/fest20/")
    .split("admin.thefestfl.com/fest19/wp-content/uploads/")
    .join("static.thefestfl.com/fest19/")
    .split("admin.thefestfl.com/fest18/wp-content/uploads/")
    .join("static.thefestfl.com/fest18/")
    .split("admin.thefestfl.com/fest17/wp-content/uploads/")
    .join("static.thefestfl.com/fest17/");

export const getShortSiteTitle = (rawUrl = "") => {
  const url = rawUrl.toLowerCase();

  if (url.includes("twitter.com")) {
    return "Twitter";
  }
  if (url.includes("facebook.com")) {
    return "Facebook";
  }
  if (url.includes("instagram.com")) {
    return "Instagram";
  }
  if (url.includes("bandcamp.com")) {
    return "Bandcamp";
  }
  if (url.includes("spotify.com")) {
    return "Spotify";
  }
  if (url.includes("itunes.apple.com")) {
    return "iTunes";
  }
  if (url.includes("soundcloud.com")) {
    return "Soundcloud";
  }
  if (url.includes("youtube.com")) {
    return "YouTube";
  }
  if (url.includes("tumblr.com")) {
    return "Tumblr";
  }

  return getDomainFromUrl(url);
};

export const getThirdPartySiteIcon = (rawUrl = "") => {
  const url = rawUrl.toLowerCase();

  if (url.includes("twitter.com")) {
    return ["fab", "twitter"];
  }
  if (url.includes("facebook.com")) {
    return ["fab", "facebook"];
  }
  if (url.includes("instagram.com")) {
    return ["fab", "instagram"];
  }
  if (url.includes("bandcamp.com")) {
    return ["fab", "bandcamp"];
  }
  if (url.includes("spotify.com")) {
    return ["fab", "spotify"];
  }
  if (url.includes("itunes.apple.com")) {
    return ["fab", "itunes"];
  }
  if (url.includes("soundcloud.com")) {
    return ["fab", "soundcloud"];
  }
  if (url.includes("youtube.com")) {
    return ["fab", "youtube"];
  }
  if (url.includes("tumblr.com")) {
    return ["fab", "tumblr"];
  }

  return ["fa", "external-link-alt"];
};
