import * as types from "../constants/ActionTypes";

export const playTrackByTrackId = (trackId = "") => ({
  type: types.PLAY_TRACK_BY_TRACK_ID,
  trackId
});
export const radioPlay = () => ({
  type: types.RADIO_PLAY
});
export const radioPause = () => ({
  type: types.RADIO_PAUSE
});
export const radioStop = () => ({
  type: types.RADIO_STOP
});
export const radioNextTrack = () => ({
  type: types.RADIO_NEXT_TRACK
});
export const radioShuffleOff = () => ({
  type: types.RADIO_SHUFFLE_OFF
});
export const radioShuffleOn = () => ({
  type: types.RADIO_SHUFFLE_ON
});
