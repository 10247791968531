import cx from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Link, withPrefix } from "gatsby";

import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sound from "react-sound";

import { COLORS } from "../../utils/theme";

import Button from "../Forms/Button";
import { StyledComponentsBreakpoints } from "../Layout/Responsive";

import {
  radioPlay,
  radioPause,
  radioStop,
  radioNextTrack,
  radioShuffleOff,
  radioShuffleOn,
  playTrackByTrackId
} from "../../actions/index";

import { prettyPrintHtmlEntitiesInString } from "../../utils/strings";

import PLAYLIST from "../../../data/Playlist.json";

const iconColor = "rgba(255, 83, 113, 1)";
const iconFadedColor = iconColor.replace("1)", "0.311)");

class RadioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded || false,
      hidden: true
    };

    this.handleRadioPlayerClick = this.handleRadioPlayerClick.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.togglePlayPause = this.togglePlayPause.bind(this);
    this.toggleShuffleMode = this.toggleShuffleMode.bind(this);
    this.playNextTrack = this.playNextTrack.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hidden: false });
    }, 1000);
  }

  handleRadioPlayerClick(e) {
    if (!e.nativeEvent.toElement) {
      return;
    }
    if (["H6", "DIV"].includes(e.nativeEvent.toElement.nodeName)) {
      this.toggleExpanded();
    }
  }

  toggleExpanded() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  togglePlayPause() {
    const { dispatch, radio } = this.props;
    if (radio.playStatus === Sound.status.PLAYING) {
      dispatch(radioPause());
    } else if (radio.currentTrack) {
      dispatch(radioPlay());
    }
  }

  toggleShuffleMode() {
    const { dispatch, radio } = this.props;
    if (radio.shuffleMode) {
      dispatch(radioShuffleOff());
    } else {
      dispatch(radioShuffleOn());
    }
  }

  playNextTrack() {
    const { dispatch } = this.props;
    dispatch(radioNextTrack());
  }

  updateMetadata(currentTrack) {
    const { dispatch } = this.props;
    const windowNavigator = typeof navigator === "undefined" ? {} : navigator;
    if ("mediaSession" in windowNavigator) {
      windowNavigator.mediaSession.metadata = new MediaMetadata({
        title: currentTrack.track,
        artist: currentTrack.artist,
        album: "FEST Radio",
        artwork: [
          {
            src: withPrefix("/favicons/android-chrome-36x36.png"),
            sizes: "36x36",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-48x48.png"),
            sizes: "48x48",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-72x72.png"),
            sizes: "72x72",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-96x96.png"),
            sizes: "96x96",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-144x144.png"),
            sizes: "144x144",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-192x192.png"),
            sizes: "192x192",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-256x256.png"),
            sizes: "256x256",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-384x384.png"),
            sizes: "384x384",
            type: "image/png"
          },
          {
            src: withPrefix("/favicons/android-chrome-512x512.png"),
            sizes: "512x512",
            type: "image/png"
          }
        ]
      });

      windowNavigator.mediaSession.setActionHandler("play", () => {
        dispatch(radioPlay());
      });
      windowNavigator.mediaSession.setActionHandler("pause", () => {
        dispatch(radioPause());
      });
      windowNavigator.mediaSession.setActionHandler("nexttrack", () => {
        dispatch(radioNextTrack());
      });
    }
  }

  render() {
    const { dispatch, radio } = this.props;
    const { hidden, expanded } = this.state;

    const { currentTrack, playStatus, shuffleMode } = radio;

    if (hidden) {
      return <div />;
    }

    this.updateMetadata(radio.currentTrack);

    return (
      <RadioPlayerContainer
        className={cx({
          expanded
        })}
      >
        {currentTrack && currentTrack.url && (
          <Sound
            url={currentTrack.url}
            playStatus={playStatus}
            onFinishedPlaying={() => {
              dispatch(radioNextTrack());
            }}
          />
        )}
        <Playbar onClick={this.handleRadioPlayerClick}>
          <NowPlayingContainer>
            {(currentTrack || playStatus !== Sound.status.STOPPED) && (
              <div>
                {currentTrack.artist ? (
                  <h6
                    title={prettyPrintHtmlEntitiesInString(
                      `${currentTrack.artist} - ${currentTrack.track}`
                    )}
                  >
                    <FontAwesomeIcon
                      icon={["fa", "headphones"]}
                      color={iconColor}
                    />
                    {" "}
                    <Link to={`/bands/${currentTrack.slug}`}>
                      {prettyPrintHtmlEntitiesInString(currentTrack.artist)}
                    </Link>
                    {" "}
                    - 
                    {' '}
                    {currentTrack.track}
                  </h6>
                ) : (
                  <h6>
                    <FontAwesomeIcon
                      icon={["fa", "headphones"]}
                      color={iconColor}
                    />
                    {" "}
                    #FEST22 RADIO
                  </h6>
                )}
              </div>
            )}
          </NowPlayingContainer>
          <Toolbar>
            <Button
              title="Play/Pause"
              variant="flat"
              noDelay
              onClick={this.togglePlayPause}
            >
              {playStatus === Sound.status.PLAYING ? (
                <FontAwesomeIcon icon={["fa", "pause"]} color={iconColor} />
              ) : (
                <FontAwesomeIcon icon={["fa", "play"]} color={iconColor} />
              )}
            </Button>

            <Button
              variant="flat"
              noDelay
              onClick={() => dispatch(radioStop())}
            >
              {playStatus === Sound.status.STOPPED ? (
                <FontAwesomeIcon icon={["fa", "stop"]} color={iconColor} />
              ) : (
                <FontAwesomeIcon icon={["fa", "stop"]} color={iconColor} />
              )}
            </Button>

            <Button variant="flat" noDelay onClick={this.playNextTrack}>
              <FontAwesomeIcon
                icon={["fa", "step-forward"]}
                color={iconColor}
              />
            </Button>
            <Button variant="flat" noDelay onClick={this.toggleShuffleMode}>
              {shuffleMode ? (
                <FontAwesomeIcon icon={["fa", "random"]} color={iconColor} />
              ) : (
                <FontAwesomeIcon
                  icon={["fa", "random"]}
                  color={iconFadedColor}
                />
              )}
            </Button>
            <Button variant="flat" noDelay onClick={this.toggleExpanded}>
              {expanded ? (
                <FontAwesomeIcon
                  icon={["fa", "angle-down"]}
                  color={iconColor}
                />
              ) : (
                <FontAwesomeIcon icon={["fa", "angle-up"]} color={iconColor} />
              )}
            </Button>
          </Toolbar>
        </Playbar>

        <PlaylistContainer>

        {expanded ? (
          <Playlist>
            
            {PLAYLIST.map((track, i) => {
              const artistName = track.artist;
              const trackName = track.track;

              return (
                <PlaylistItem key={track.trackId}>
                  <Button
                    variant="flat"
                    onClick={() =>
                      this.props.dispatch(playTrackByTrackId(track.trackId))}
                  >
                    <PlaylistItemContent>
                      <TrackNumber>{`${++i}. `}</TrackNumber>
                      <TrackName>
                        {prettyPrintHtmlEntitiesInString(
                          `${artistName} - ${trackName}`
                        )}
                      </TrackName>
                    </PlaylistItemContent>
                  </Button>
                </PlaylistItem>
              );
            })}
          </Playlist>
        ) : ( 
          <></>
        )}
        </PlaylistContainer>
      </RadioPlayerContainer>
    );
  }
}

const mapStateToProps = state => ({
  radio: state.radio
});

export const ConnectedRadioPlayer = connect(mapStateToProps)(RadioPlayer);
export const RADIO_PLAYER_HEIGHT = 60;

const TOOLBAR_WIDTH = 180;
const ICON_SIZE = 16;

const fadeInAnimation = keyframes`${fadeIn}`;

const RadioPlayerContainer = styled.div`
  animation: 0.311s ${fadeInAnimation};
  position: fixed;
  left: calc(100% - 480px);
  right: 0;
  background: ${COLORS.lightgrey.hex};
  
  color: ${COLORS.black.hex};
  box-shadow: 0px 0px 0px 2px rgba(21, 23, 23, 0.1);
  bottom: 0;
  height: ${RADIO_PLAYER_HEIGHT}px;
  cursor: pointer;

  z-index: 999;

  &.expanded {
    top: calc(100% - 320px);
    height: inherit;
  }

  ${StyledComponentsBreakpoints.Phone`
    left: 0;

    &.expanded {
      top: 72px;
    }
  `};
`;

const Playbar = styled.div`
  margin: 0 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  height: ${RADIO_PLAYER_HEIGHT}px;

  svg {
    height: ${ICON_SIZE}px !important;
    width: ${ICON_SIZE}px !important;
  }
`;
const NowPlayingContainer = styled.div`
  order: 1;
  align-self: center;
  flex: 1 0 calc(100% - ${TOOLBAR_WIDTH}px);

  min-width: 0;

  div h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;


    font-family: "Roboto Condensed", "Roboto", -apple-system, BlinkMacSystemFont,
      "segoe ui", arial, "ubuntu", sans-serif;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.6;
    letter-spacing: 0.0311em;
    a {
      color: ${COLORS.black.hex} !important;
    }
  }

  svg {
    margin: 0 8px;
  }

  ${StyledComponentsBreakpoints.Phone`

      div h6 { margin-left: 8px; }
      svg { 
        display: none;
      }
  `};
`;
const Toolbar = styled.div`
  order: 2;
  align-self: center;
  text-align: right;

  flex: 1 0 ${TOOLBAR_WIDTH}px;

  button {
    min-width: 0;

    margin: 8px 0 8px 0 !important;
    padding: 8px !important;

    &:last-child {
      margin-left: 12px !important;
    }
  }
`;

const PlaylistContainer = styled.div`
  overflow-y: scroll;
  position: relative;
  height: 100%;
  padding-bottom: 24px;
`;

const Playlist = styled.ul`
  padding-bottom: 36px;
`;
const PlaylistItem = styled.li`
  padding: 0;
  margin: 0 auto;
  line-height: 24px;
  text-align: center;

  button {
    padding: 10px 0 !important;
    margin: 0 !important;
    width: 100%;

    min-width: 100%;

    span {
      justify-content: left;
    
      h6 {
        text-align: left;
        width: 95%;
        max-width: 95%;
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &:nth-child(odd) {
    button {
      background: rgba(6, 6, 6, 0.05);
    }
  }
`;
const PlaylistItemContent = styled.div`
  width: 100%;
  text-align: left;
  margin: 0 auto;

  ${StyledComponentsBreakpoints.Phone`
    font-size: 0.75rem;
  `};
`;

const TrackNumber = styled.div`
  display: inline-block;
  min-width: 28px;
  width: 28px;
  text-align: right;
  margin-right: 4px;

  color: #6d6e70;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Roboto Condensed", "Roboto", -apple-system, BlinkMacSystemFont,
    "segoe ui", arial, "ubuntu", sans-serif;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.6;
  letter-spacing: 0.0311em;

  ${StyledComponentsBreakpoints.Phone`
    font-size: 0.75rem;
  `};
`;

const TrackName = styled.div`
  display: inline-block;
  color: #040200;
  max-width: 90%;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Roboto Condensed", "Roboto", -apple-system, BlinkMacSystemFont,
  "segoe ui", arial, "ubuntu", sans-serif;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.6;
  letter-spacing: 0.0311em;
`;
