import { createStore, compose } from "redux";

import rootReducer from "../reducers/index";

export default function configureStore(initialState = {}) {
  let enhancer;
  let store;

  if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    /* eslint-enable */

    store = createStore(rootReducer, initialState, composeEnhancers());
  } else if (typeof localStorage !== "undefined") {
    enhancer = compose();
    store = createStore(rootReducer, initialState, enhancer);
  } else {
    store = createStore(rootReducer, initialState);
  }

  if (module.hot) {
    module.hot.accept("../reducers", () => {
      /* eslint-disable global-require */
      const nextRootReducer = require("../reducers/index");
      /* eslint-enable */
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
