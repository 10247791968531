module.exports = {
  siteTitle: "The FEST 22",
  siteTitleExt: "October 25-27, 2024 - Gainesville, FL",
  siteUrl: "https://thefestfl.com",
  pathPrefix: "/", // Prefixes all links.
  siteDescription:
    "THE FEST is an independent multi-day, multiple-venue underground music festival held annually in Gainesville, Florida. In 2024, THE FEST celebrates 22 YEARS in Gainesville!",
  siteKeywords:
    "the fest, the fest florida, fest 22, the fest 22, the fest 22 gainesville, gainesville, florida, punk, punk festivals, punk rock, music festivals, music festivals 2024",
  copyright: "© 2024 THE FEST FL",
  twitterAccount: "@thefestfl"
};
