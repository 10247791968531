import Sound from "react-sound";
import * as types from "../constants/ActionTypes";
import completePlaylist from "../../data/Playlist.json";

const initialState = {
  playStatus: Sound.status.STOPPED,
  playlist: completePlaylist,
  currentTrack: {},
  shuffleMode: true
};

const getTrackById = (trackId, playlist) =>
  playlist.find(track => track.trackId === trackId);

const getRandomTrack = playlist =>
  playlist[Math.floor(Math.random() * playlist.length)];

const getNextTrack = (trackId = -1, playlist, shuffleMode = false) => {
  let nextIndex;

  if (shuffleMode) {
    return getRandomTrack(playlist);
  }

  nextIndex = playlist.findIndex(track => track.trackId === trackId) + 1;
  if (nextIndex === -1 || nextIndex >= playlist.length) {
    nextIndex = 0;
  }

  return playlist[nextIndex];
};

const radio = (state = initialState, action) => {
  switch (action.type) {
    case types.PLAY_TRACK_BY_TRACK_ID:
      return Object.assign({}, state, {
        currentTrack: getTrackById(action.trackId, state.playlist),
        playStatus: Sound.status.PLAYING
      });
    case types.RADIO_PLAY:
      return Object.assign({}, state, {
        playStatus: Sound.status.PLAYING,
        currentTrack: state.currentTrack.trackId
          ? state.currentTrack
          : getRandomTrack(state.playlist)
      });
    case types.RADIO_PAUSE:
      return Object.assign({}, state, { playStatus: Sound.status.PAUSED });
    case types.RADIO_STOP:
      return Object.assign({}, state, { playStatus: Sound.status.STOPPED });
    case types.RADIO_NEXT_TRACK:
      return Object.assign({}, state, {
        currentTrack: getNextTrack(
          state.currentTrack.trackId,
          state.playlist,
          state.shuffleMode
        )
      });
    case types.RADIO_SHUFFLE_OFF:
      return Object.assign({}, state, { shuffleMode: false });
    case types.RADIO_SHUFFLE_ON:
      return Object.assign({}, state, { shuffleMode: true });
    default:
      return state;
  }
};

export default radio;
