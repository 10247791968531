import React from "react";
import Helmet from "react-helmet";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { ConnectedRadioPlayer as FestRadio } from "../components/Radio/RadioPlayer";

import siteConfig from "../../data/SiteConfig";
import "./index.css";

import "fontsource-league-gothic";

import "@fontsource/rubik"; // Defaults to weight 400
import "@fontsource/rubik/300.css"; // Specify weight
import "@fontsource/rubik/400.css"; // Specify weight

require("typeface-roboto");

library.add(fas, fab);

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class MainLayout extends React.Component {
  getLocalTitle() {
    const { location } = this.props;
    const pathPrefix = siteConfig.pathPrefix ? siteConfig.pathPrefix : "/";
    const currentPath = location.pathname
      .replace(pathPrefix, "")
      .replace("/", "");
    let title = "";
    if (currentPath === "") {
      title = "Home";
    } else if (currentPath === "tags/") {
      title = "Tags";
    } else if (currentPath === "categories/") {
      title = "Categories";
    } else if (currentPath.indexOf("posts")) {
      title = "Article";
    } else if (currentPath.indexOf("tags/")) {
      const tag = currentPath
        .replace("tags/", "")
        .replace("/", "")
        .replace("-", " ");
      title = `Tagged in ${capitalize(tag)}`;
    } else if (currentPath.indexOf("categories/")) {
      const category = currentPath
        .replace("categories/", "")
        .replace("/", "")
        .replace("-", " ");
      title = `${capitalize(category)}`;
    }
    return title;
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet>
          <title>{`${siteConfig.siteTitle} - ${this.getLocalTitle()}`}</title>
          {/* General tags */}
          <meta name="description" content={siteConfig.siteDescription} />
          <meta name="keywords" content={siteConfig.siteKeywords} />

          {/* OpenGraph tags */}
          <meta property="og:url" content={siteConfig.siteUrl} />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`${siteConfig.siteTitle} - ${siteConfig.siteTitleExt}`}
          />
          <meta
            property="og:description"
            content={siteConfig.siteDescription}
          />
          <meta
            property="og:image"
            content="https://thefestfl.com/images/fest-og-image.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={siteConfig.twitterAccount} />

          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.1/css/brands.css"
            integrity="sha384-n9+6/aSqa9lBidZMRCQHTHKJscPq6NW4pCQBiMmHdUCvPN8ZOg2zJJTkC7WIezWv"
            crossOrigin="anonymous"
          />
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.1/css/fontawesome.css"
            integrity="sha384-vd1e11sR28tEK9YANUtpIOdjGW14pS87bUBuOIoBILVWLFnS+MCX9T6MMf0VdPGq"
            crossOrigin="anonymous"
          />
        </Helmet>

        {children}



        <FestRadio />

      </div>
    );
  }
}
