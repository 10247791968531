import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

import { FONT_FAMILIES, COLORS } from "../../utils/theme";

const BUTTON_STYLE_MAP = {
  default: {
    background: COLORS.darkgrey.hex,
    textTransform: "uppercase",
    margin: "16px 0",
    padding: "8px 12px",
    borderRadius: 3,
    border: 0,
    boxShadow: "1px 2px 5px 1px rgba(21, 23, 23, .311)"
  },
  flat: {
    textTransform: "uppercase",
    margin: "16px 0",
    padding: "8px 12px"
  }
};

const ButtonComponent = props => {
  const { children, noDelay, onClick, variant } = props;
  const buttonStyle =
    variant && typeof BUTTON_STYLE_MAP[variant] !== "undefined"
      ? BUTTON_STYLE_MAP[variant]
      : BUTTON_STYLE_MAP.default;

  const timeout = noDelay ? 0 : 420;

  return (
    <Button style={buttonStyle} onClick={() => setTimeout(onClick, timeout)}>
      <ButtonCopy>{children}</ButtonCopy>
    </Button>
  );
};

export default ButtonComponent;

const ButtonCopy = styled.span`

  ${FONT_FAMILIES.headline};

  color: ${COLORS.white.hex};
  font-size: 13px;
  font-weight: 800;
  transition: all 311ms ease;
  &:hover {
    color: ${COLORS.blue.hex};
  }
`;
