import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import wrapWithProvider from "./wrap-with-provider";
import "./src/layouts/index.css";
import "./node_modules/@fortawesome/fontawesome-svg-core/styles.css";

import "fontsource-league-gothic";

require("typeface-roboto");

library.add(fas, fab);

export const wrapRootElement = wrapWithProvider;
